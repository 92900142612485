<template>
  <div class="couponsOrder">
    <div class="title">
      <span style="font-size: 20px; margin-right: 20px">优惠券信息</span>
      <!-- <span
        style="
          font-size: 12px;
          font-weight: 600;
          color: #55cc77;
          background: #e7f9ef;
          padding: 2px 4px;
          margin-left: 10px;
        "
        >进行中的活动</span
      > -->
      <span class="tag" v-if="status == '接下来的活动'">接下来的活动</span>
      <span class="tag1" v-if="status == '进行中的活动'">进行中的活动</span>
      <span class="tag2" v-if="status == '已过期'">已过期</span>
    </div>
    <div class="cell1">
      <div style="font-size: 16px">基本详情</div>
      <div class="cell1-list">
        <!-- <div>
          <div></div>
        </div>
        <div></div>
        <div></div> -->
        <div
          :style="{ width: (i + 1) % 3 == 0 ? '40%' : '30%', padding: '14px' }"
          v-for="(item, i) in infoData"
          :key="i"
        >
          <span style="color: #999999">{{ item.title }}</span>
          {{ item.val }}
        </div>
      </div>
    </div>
    <div class="cell1">
      <div style="font-size: 16px; padding: 10px 0 20px">
        优惠券订单
        <!-- <span style="font-size: 14px">
          （统计时间:GMT+08 2022/05/12 14:10 至 2022/05/12 14:10 ）</span
        > -->
      </div>
      <div class="cell2-list">
        <div class="list-head">
          <div style="width: 5%">排序</div>
          <div style="width: 25%">商品</div>
          <div style="width: 15%">规格</div>
          <div style="width: 10%">原价/活动价格</div>
          <div style="width: 10%">折扣金额</div>
          <div style="width: 10%">折扣价格</div>
          <div style="width: 10%; display: flex; align-items: center">
            <span style="margin-right: 6px"> 售出件数</span>
            <div>
              <div
                :class="
                  field == 'number' && order == 1 ? 'triangleC' : 'triangleT'
                "
                @click="handleSort('number', 1)"
              ></div>
              <div
                :class="
                  field == 'number' && order == 2 ? 'triangleD' : 'triangleB'
                "
                @click="handleSort('number', 2)"
              ></div>
            </div>
          </div>
          <div style="width: 5%; display: flex; align-items: cente">
            <span style="margin-right: 6px"> 销售额</span>
            <div>
              <div
                :class="
                  field == 'actual_payment' && order == 1
                    ? 'triangleC'
                    : 'triangleT'
                "
                @click="handleSort('actual_payment', 1)"
              ></div>
              <div
                :class="
                  field == 'actual_payment' && order == 2
                    ? 'triangleD'
                    : 'triangleB'
                "
                @click="handleSort('actual_payment', 2)"
              ></div>
            </div>
          </div>
        </div>
        <div v-if="listData.list && listData.list.length != 0">
          <div
            class="list-content"
            v-for="(item, i) in listData.list"
            :key="item.id"
            :style="{ border: i == 0 ? 'none' : '' }"
          >
            <div style="width: 5%">{{ item.id }}</div>
            <div style="width: 25%; display: flex; font-size: 14px">
              <div>
                <el-image
                  style="width: 50px; height: 50px; margin-right: 10px"
                  :src="item.image"
                  fit="fit"
                ></el-image>
              </div>
              <div>
                <div>{{ item.title }}</div>
                <div style="font-size: 12px; margin-top: 4px; color: #b0b0b0">
                  全球商品货号：{{
                    item.goods_sku_sn ? item.goods_sku_sn : "-"
                  }}
                </div>
              </div>
            </div>
            <div style="width: 15%">
              {{ item.difference ? item.difference : "-" }}
            </div>
            <div style="width: 10%">RM{{ item.price }}</div>
            <div style="width: 10%">
              {{
                item.discount_coupon_type == 1
                  ? "RM" + item.discount_amount
                  : item.discount_amount + "折"
              }}
            </div>
            <div style="width: 10%">RM{{ item.discount_price }}</div>
            <div style="width: 10%">{{ item.number }}</div>
            <div style="width: 5%">RM{{ item.actual_payment }}</div>
          </div>
        </div>
        <div
          v-else
          style="text-align: center; color: #b0b0b0; line-height: 100px"
        >
          暂无
        </div>
      </div>
      <div style="display: flex; justify-content: right; padding-top: 20px">
        <el-pagination
          :page-size="page_size"
          :current-page="page"
          background
          layout="prev, pager, next"
          :total="listData.total"
          @current-change="pageChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { getCouponInfo, couponOrderList } from "@/api/marketing.js";
export default {
  data() {
    return {
      url: "",
      infoData: [
        { key: "coupon_name", title: "优惠券名称：", val: "" },
        { key: "discount_type", title: "奖励类型：", val: "" },
        { key: "min_amount", title: "最低消费金额：", val: "" },
        { key: "discount_amount", title: "折扣金额：", val: "" },
        { key: "max_amount", title: "最高上限数额：", val: "" },
        { key: "time", title: "优惠券领取期限：", val: "" },
        { key: "coupon_code", title: "优惠码：", val: "" },
        { key: "coupon_type", title: "优惠券类型：", val: "" },
        { key: "coupon_goods", title: "优惠商品：", val: "" },
        { key: "available_number", title: "数量：", val: "" },
        { key: "received_number", title: "已领取：", val: "" },
        { key: "used_number", title: "已使用：", val: "" },
      ],
      status: "",
      page: 1,
      page_size: 10,
      listData: {
        list: [],
      },
      field: "number",
      order: 0,
    };
  },
  created() {
    this.getInfo();
    this.getList();
  },
  methods: {
    getInfo() {
      getCouponInfo({
        id: sessionStorage.COUPONSID,
      }).then((res) => {
        if (res.code == 1) {
          this.status = res.data.status;
          this.infoData.forEach((item, index) => {
            Object.keys(res.data).forEach((val) => {
              if (item.key == val) {
                this.infoData[index].val = res.data[val];
              }
            });
            // 优惠券类型
            if (item.key == "coupon_type") {
              item.val = item.val == 1 ? "店铺优惠券" : "商品优惠券";
            }
            // 折扣类型
            if (item.key == "discount_type") {
              item.val = item.val == 1 ? "折扣金额" : "折扣";
            }
            // 折扣金额
            if (item.key == "discount_amount") {
              item.val =
                res.data.discount_type == 1
                  ? "RM" + item.val
                  : item.val / 10 + "折";
            }
            // 领取期限
            if (item.key == "time") {
              item.val =
                this.formatTime(res.data.start_time) +
                "至" +
                this.formatTime(res.data.end_time);
            }
            // 优惠商品
            if (item.key == "coupon_goods") {
              item.val = res.data.coupon_type == 1 ? "全部商品" : "指定商品";
            }
            // 优惠商品
            if (item.key == "max_amount") {
              item.val = res.data.max_amount == 0 ? "无限制" : item.val;
            }
            if (item.key == "min_amount") {
              item.val = "RM" + res.data.min_amount;
            }
          });
        }
      });
    },
    getList() {
      couponOrderList({
        coupon_id: sessionStorage.COUPONSID,
        field: this.field,
        order: this.order,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        if (res.code == 1) {
          this.listData = res.data;
        }
      });
    },
    pageChange(index) {
      this.page = index;
      this.getList();
    },
    // 排序
    handleSort(type, val) {
      this.field = type;
      this.order = val;
      this.$forceUpdate();
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.couponsOrder {
  width: 1400px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 40px;
  .title {
    .tag1 {
      background: #eaf9ef;
      color: #55cc77;
      display: initial;
      padding: 2px 4px;
      border-radius: 2px;
      font-size: 12px;
    }
    .tag {
      background: #fff1f0;
      color: #ee4d2d;
      display: initial;
      padding: 2px 4px;
      font-size: 12px;
      border-radius: 2px;
    }
    .tag2 {
      background: #eeeeee;
      color: #666;
      font-size: 12px;
      display: initial;
      padding: 2px 4px;
      border-radius: 2px;
    }
  }
  .cell1 {
    padding: 20px;
    background: #fff;
    border-radius: 2px;
    margin-top: 20px;
    .cell1-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      // justify-content: space-between;
      font-size: 14px;
    }
    .cell2-list {
      border: 1px solid #eee;
      border-radius: 4px;
      .list-head {
        display: flex;
        justify-content: space-between;
        background: #f6f6f6;
        padding: 10px 20px;
        font-size: 14px;
      }
      .list-content {
        display: flex;
        justify-content: space-between;
        padding: 40px 0;
        margin: 0 20px;
        font-size: 14px;
        align-items: center;
        border-top: 1px solid #eee;
      }
    }
  }
}
</style>